<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Transactions" subtitle="See all the payments and refunds made.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no transactions found.">

		<template v-slot:persist>
				
			<app-content-summary :fixed="true">

				<app-content-summary-item :grow="true" label="Payments" :value="$convention.count.billing.sales_total | formatMoney('+')" />
				<app-content-summary-item :grow="true" label="Refunds" :value="$convention.count.billing.refunds_total | formatMoney('-')" />
				<app-content-summary-item :grow="true" label="Net" :value="net | formatMoney('=')" />

			</app-content-summary>

		</template>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Type" />
				<app-table-column text="Date" />
				<app-table-column text="Sale No." />
				<app-table-column text="Amount" />

			</app-table-head>

			<app-table-body>

				<app-table-row :no-click="true" :warning="item.type === 2" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="$constants.billing.transaction.type[item.type]" />
					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :can-empty="true" :text="(item.sale) ? item.sale.reference : false" />
					<app-table-cell-text :text="item.amount | formatMoney" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '80px auto 120px 120px',
			endpoint: 'convention/billing/transactions',
			live: 'convention/billing/transactions',
			edit: 'Convention.Billing.Transactions.Edit'
		}

	},

	computed: {

		net: function() {

			return this.$convention.count.billing.sales_total - this.$convention.count.billing.refunds_total

		}

	}

}

</script>

<style scoped>

.summary-wrapper {
	display: flex;
}

.summary-wrapper-badge {
	margin-left: 10px;
	margin-right: 10px;
}

</style>